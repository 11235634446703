@import '../../styles/utils/mixins';
@import '../../styles/utils/media-queries';

.primary {
  @include buttonsCommon();
    align-self: flex-start;
    background: var(--primary-color-gradiant);
    color: var(--base-bg-color-dark);
    width: 100%;
    height: 5.4rem;

    &:hover {
      background: linear-gradient(-180deg, #a47c00 81.77%, #d9cda7 18.23%);
      background: linear-gradient(180deg, #a47c00 18.23%, #d9cda7 81.77%);
    }
}

.secondary {
  @include buttonsCommon();
  border: 2px solid;
  border-color: #a47c00;
  color: #a47c00;
  transition: all 0.5s;

  &:hover {
    box-shadow: inset 0 0 0 2em #a47c00;
    color: var(--base-bg-color-light);
  }
}