
@import '../../../styles/utils/mixins';

.footer {
    border-top: 1px solid var(--primary-color);
    background: var(--base-bg-color-dark);
}

.container {
    @include container;
}