@import '../../styles/utils/mixins';
@import '../../styles/utils/media-queries';

.cookieConsent {
  color: var(--base-bg-color-light);
  font-size: 1.4rem;
  background-color: var(--base-bg-color-dark);
  z-index: var(--cookie-consent-index);
  padding: var(--cookie-consent-padding, 1.6rem 2.4rem 4rem);
  border-top: 1px solid var(--primary-color);
  box-shadow: 0px 4px 6px -2px rgba(27, 27, 28, 0.02), 0px 12px 16px -4px rgba(27, 27, 28, 0.05);
  gap: 0;
  display: none;

  @include min(tablet) {
    padding: var(--cookie-consent-desktop-padding, 1.6rem 1rem 2.4rem);
  }
  display: none;

  > div {
    @include flex-align(flex-start, space-between);
    flex-direction: column;
    gap: var(--cookie-consent-gap, 2.4rem);
    margin: 0 auto;
    
    @include min(tablet) {
      flex-direction: row;
      align-items: center;
      padding: var(--cookie-consent-top-desktop-padding, 0rem 2.3rem !important);
      gap: var(--cookie-consent-desktop-gap, 3.2rem);
    }
  }
}


.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--cookie-consent-button-gap, 0.8rem);

  @include min(tablet) {
    gap: var(--cookie-consent-desktop-button-gap, 0.8rem);
  }
  button:last-child{
    background-color: var(--primary-button-color, #6e33e5);
  }
  button {
    height: var(--cookie-consent-button-height, 5.6rem);
    display: flex;
    border-radius: var(--cookie-consent-button-radius, 10rem);
    padding: var(--cookie-consent-button-padding, 1.6rem 2.4rem);
    font-size: var(--cookie-consent-button-size, 1.8rem);
    font-weight: var(--cookie-consent-button-weight, 700);
    line-height: var(--cookie-consent-button-lineHeight, 2.6rem);
    text-transform: capitalize;
    width: 100%;
    background: var(--cookie-consent-button);
    color: var(--cookie-consent-button-color, white) !important;

    &:first-child {
      color: var(--cookie-consent-first-button-color, #1b1b1c) !important;
      background-color: var(--cookie-consent-first-button-bg, white);
      border: var(--cookie-consent-first-button-border, 2px solid #262629);

      &:hover {
        background: var(--cookie-consent-first-button-bg, white) !important;
        background-color: var(--cookie-consent-first-button-bg, white) !important;
      }
    }

    @include min(laptop) {
      margin: 0;
      width: auto;
      margin-bottom: 0px;
      height: var(--cookie-consent-desktop-button-height, 5.6rem);
      padding: var(--cookie-consent-desktop-button-padding, 1.6rem 2.4rem);
      font-size: var(--cookie-consent-desktop-button-size, 1.8rem);
      font-weight: var(--cookie-consent-desktop-button-weight, 700);
      line-height: var(--cookie-consent-desktop-button-lineHeight, 2.6rem);
    }
  }

  @include max(tablet) {
    flex-direction: column;
    width: 100%;
  }
}

.content {
  color: var(--cookie-consent-content-color, #ffffff);
  font-size: var(--cookie-consent-text-font, 1.4rem);
  font-weight: var(--cookie-consent-text-weight, 500);
  line-height: var(--cookie-consent-text-lineHeight, 2.2rem);

  @include min(tablet) {
    font-size: var(--cookie-consent-desktop-text-font, 1.4rem);
    font-weight: var(--cookie-consent-desktop-text-weight, 500);
    line-height: var(--cookie-consent-desktop-text-lineHeight, 2.2rem);
  }

  a {
    color: var(--cookie-consent-text-link-color, #165af8);
    font-weight: var(--cookie-consent-text-link-weight, 600);
    margin-left: 0.3rem;
    text-decoration: var(--cookie-consent-text-link-underline, underline);
  }
}

.show {
  display: block;
}

.cookieButton {
  cursor: pointer;
  z-index: var(--cookieButton-index, 5);
  background-color: var(--cookieButton-bg, white);
  box-shadow: var(
    --cookieButton-shadow,
    0px 4px 6px -2px rgba(27, 27, 28, 0.02),
    0px 12px 16px -4px rgba(27, 27, 28, 0.05)
  );
  border-radius: 100%;
  padding: 0.8rem;
  @include flex-align(center, center);
  width: 4.8rem;
  height: 4.8rem;
  margin: 1rem;
  position: absolute;
  bottom: calc(100%);
  left: 0;

  @include min(tablet) {
    width: 5.6rem;
    height: 5.6rem;
  }

  > img {
    width: 2.8rem;
    height: 2.8rem;

    @include min(tablet) {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

.show {
  display: block;
}