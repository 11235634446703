
@import '../../../styles/utils/mixins';
@import '../../../styles/utils/media-queries';

.header {
    height: 5.6rem;

    > nav {
        border-bottom: 1px solid var(--primary-color);
        background: var(--base-bg-color-dark);
        color: var(--base-bg-color-light);

        > .container {
            > button {
                position: absolute;
                left: 0.75rem;
            }
        }
    }
}

.logoContainer {
    @include flex-align(center, center);
    margin: 0;

    span {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.4rem;    
        -webkit-text-fill-color: transparent;
        background: var(--primary-color-gradiant);
        -webkit-background-clip: text;
        background-clip: text;

        @include min(tablet) {
            font-size: 2.4rem;
            line-height: 2.8rem;
        }

        @include min(laptop) {
            @include flex-align(center, flex-start);
        }
    }
}

.logo {
    width: 4rem;
}

.dropdownContainer {
    position: relative;
}

.dropdown {
    @include flex-align(center, center);
    background-color: var(--base-bg-color-dark);
    border: 2px solid var(--primary-color);
    border-radius: 100px;
    padding: 0.5rem 3rem 0.5rem 1rem;
    position: relative;
    height: 3.8rem;

    &::after {
        border: solid #fff;
        border-width: 0 0.2333333333rem 0.2333333333rem 0;
        content: "";
        padding: 0.2333333333rem;
        position: absolute;
        right: 1.5rem;
        top: 1.3rem;
        transform: rotate(45deg);
    }

    > img {
        max-height: 3.2rem;
        max-width: 20rem;
        object-fit: contain;
    }
}


.dropdownActive {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}
  
.dropdownOptions {
    @include flex-direction(column);
    display: none;
    background-color: var(--base-bg-color-dark);
    border: 2px solid var(--primary-color);
    border-radius: 1rem;
    position: absolute;
    top: 99%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0.5rem 3rem 0.5rem 1rem;
    border-top: none;
    margin-right: 0;
}
  
.active {
    display: flex;
}