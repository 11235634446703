@import '../../styles/utils/mixins';
@import '../../styles/utils/media-queries';

.floatingArea {
    @include flex-direction(column);
    width: 100%;
    position: fixed;
    transition: all 1s;
    bottom: -40rem;
    z-index: 20;
}

.show {
    bottom: 0;
}