@import '../../styles/utils/mixins';
@import '../../styles/utils/media-queries';

.showScrollToTopButton {
  @include max(mobile) {
      display: none !important;
  }
}

.scrollToTop {
  z-index: 1;
  border-radius: 50%;
  position: relative;
  background: var(--primary-color-gradiant);
  width: 5rem;
  height: 5rem;
  text-align: center;
  margin: 1rem;
  position: absolute;
  bottom: calc(100%);
  right: 0;

  &:before {
    @include arrow(#000, 1.5rem, up, false);
    position: absolute;
    bottom: calc(50% - 1rem);
    left: calc(50% - 0.7rem);

    @include min(tablet) {
      bottom: calc(50% - 1rem);
      left: calc(50% - 0.6rem);
    }
  }
}
