// Global styles
*,
*:after,
*:before {
    @include box-sizing(border-box);
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 100%;
    font-size: 10px; // For easier rem calculations
}

//
// Remove default margin.
//
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    font-family: var(--font-family-inter-regular), "Helvetica", "Arial", "Sans-serif";
    color: var(--base-color);
    background: var(--base-bg-color-light);
    overflow-x: hidden;
    font-size: var(--font-size-base);
    font-size: 1.6rem;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

img {
    border: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

//
// Remove most spacing between table cells.
//
table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

ul,
ol {
    margin: 0;
}

.app {
    position: relative; 
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(164, 124, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border-color: var(--primary-color);
} 