@import 'bootstrap/dist/css/bootstrap.min.css';

// Variables and Mixins
@import './utils/mixins';
@import './utils/media-queries';
@import './utils/vars';

// Core CSS
@import './core/font';
@import './core/normalise';
@import './core/reset';
@import './core/spacing';
@import './core/typography';

// Utilities
@import './global/global';